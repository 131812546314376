<template>
  <BaseList
    :list-query="divisions"
    :name-map="nameMap"
    route="division"
    locale-section="pages.divisions"
  ></BaseList>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "DivisionList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      divisions: gql`
        query divisions {
          list: divisions {
            id: id
            province
            hundred
            parish
            county
            municipality
          }
        }
      `
    };
  },
  methods: {
    nameMap(element) {
      return {
        name: [
          element.parish,
          element.hundred,
          element.province,
          element.municipality,
          element.county
        ]
          .filter(Boolean)
          .join("/"),
        ...element
      };
    }
  }
};
</script>
